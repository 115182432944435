import Axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

import { GridProps, LinearProgress, ThemeOptions } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { FieldBehaviorProps, FieldBehaviors, LayoutBehaviorProps, LayoutBehaviors } from "../components/behaviors";
import DeviceDisplayer from "../components/behaviors/DeviceDisplayer";
import { FormSteppers } from "../components/forms/FormLayout";
import { LoginProviders } from "../components/LoginProviders";
import { useAppInsightsContext } from "../hooks";
import { FieldTypes } from "../views/fields";
import { Layouts } from "../views/layouts";

type Document = {
  url: string;
  width?: number;
  height?: number;
};
type Tenant = {
  /** identifier of the tenant */
  name: string;
};

type FieldBehavior = {
  type: keyof typeof FieldBehaviors;
} & (Exclude<
  (typeof FieldBehaviors)[keyof typeof FieldBehaviors] extends { component: any }
    ? //@ts-ignore not sure why but spend too many times on it
      Parameters<(typeof FieldBehaviors)[keyof typeof FieldBehaviors]["component"]>[0]
    : undefined,
  keyof FieldBehaviorProps
> &
  Exclude<
    (typeof FieldBehaviors)[keyof typeof FieldBehaviors] extends { fieldModifier: any }
      ? //@ts-ignore not sure why but spend too many times on it
        Parameters<(typeof FieldBehaviors)[keyof typeof FieldBehaviors]["fieldModifier"]>[0]
      : undefined,
    keyof FieldBehaviorProps
  >);

type LayoutBehavior = {
  type: keyof typeof LayoutBehaviors;
} & Exclude<Parameters<(typeof LayoutBehaviors)[keyof typeof LayoutBehaviors]>[0], keyof LayoutBehaviorProps>;

type Field = {
  container: Omit<GridProps, "className">;
  type: keyof typeof FieldTypes;
  defaultValue?: any;
  name: string;
  behaviors?: Array<FieldBehavior>;
} & Parameters<(typeof FieldTypes)[keyof typeof FieldTypes]["component"]>[0];
type Step = {
  name: string;
  fields: Array<Field>;
};

type Form = {
  stepOrientation: keyof typeof FormSteppers;
  behaviors?: Array<LayoutBehavior>;
  fields?: Array<Field>;
  steps?: Array<Step>;
};

type ImageUrlWithStyles = string | { url: string; additionalStyles?: SystemStyleObject };

type LayoutType = {
  /** name of the layout */
  name: string;

  /** information related to the current tenant */
  tenant: Tenant;

  errorLayoutName: string;

  type: keyof typeof Layouts;

  appTitle: string;
  faviconUrl: string;
  largeFaviconUrl?: string;
  appleFaviconUrl?: string;

  headerImage?: ImageUrlWithStyles;

  form: Form;

  /** Configuration for  Microsoft Clarity. If omited clarity won't be used */
  clarity?: {
    /** Identifier of the microsoft clarity account */
    projectId: string;
  };

  /** associated documents available for this tenant/layout. could be undefined when no document are available */
  documents?: { [documentName: string]: Document };

  internationalization: {
    defaultLanguage: string;
    availableLanguages: string[];
    resources: Record<string, any>;
  };

  /** Information related to Apple SDK */
  apple?: {
    serviceIdentifier: string;
  };

  /** information related to google SDK */
  google?: {
    clientId: string;
    /** Identifier of the Google Tag Manager container. In the form of 'GTM-XXX' */
    gtmId?: string;
  };

  facebook?: {
    appId: string;
  };

  line?: {
    clientId: string;
  };

  theme?: ThemeOptions;
};

type CompleteOptionsTypes =
  | ({
      type: "addToWallet";
      isDefault?: boolean;
    } & AddToWalletCompleteOptions)
  | ({
      type: "redirect";
      isDefault?: boolean;
    } & RedirectCompleteOptions)
  | ({
      type: "reset";
      isDefault?: boolean;
    } & ResetCompleteOptions)
  | ({
      type: "redirectToPassLayout";
      isDefault?: boolean;
    } & RedirectToPassLayoutCompleteOptions);

type AddToWalletCompleteOptions = {
  form: Form;
  showAddToWalletButtons?: boolean;
  barCode?: {
    format: string;
    field: string;
  };
  autoDownloadPass?: boolean;
};

type RedirectCompleteOptions = {
  redirectUri: string;
};

type ResetCompleteOptions = {
  resetDelay?: number;
};

type RedirectToPassLayoutCompleteOptions = {
  passLayoutName: string;
};

type CustomerRegistrationLayoutType = LayoutType & {
  /** Name of the flow used when a customer is created */
  flow: string;
  completeOptions: Array<CompleteOptionsTypes>;

  showFormOnHome?: boolean;

  signinOptions: {
    providers: Array<{
      type: keyof typeof LoginProviders;
      displayProps: Omit<Parameters<typeof DeviceDisplayer>[0], "children">;
    }>;
  };
};

type PassCreationLayoutType = LayoutType & {
  flow: string;
  passLayoutName: string;
};
type PassLayoutType = LayoutType & {
  logo: ImageUrlWithStyles;
  autoDownloadPass: boolean;
  passCreation?: { flow: string };
};

type PassListLayoutType = LayoutType & {
  allowDownloadAllPasses?: boolean;
  showInactivePasses?: boolean;
};

type ErrorLayoutType = LayoutType;

type LinkDisplayerLayoutType = LayoutType & {
  qrCodeOptions?: {
    fgColor?: string;
    bgColor?: string;
    additionalStyles?: React.CSSProperties;
  };
};

type VendorLayoutType = LayoutType & {
  vendorSelector?: { ShowLanguageSelector?: boolean; showLanguageIcon?: boolean };
  methods: Array<
    | ({ type: "pre" } & PreRegistrationMethodConfiguration)
    | ({ type: "form"; form: Form } & FormRegistrationMethodConfiguration)
    | ({ type: "self" } & SelfRegistrationMethodConfiguration)
  >;
};

type PreRegistrationMethodConfiguration = {
  flow: string;
};
type FormRegistrationMethodConfiguration = {
  form: any;
  flow: string;
  complete?: {
    barCode?: {
      field: string;
      format?: string;
    };
  };
};
type SelfRegistrationMethodConfiguration = {
  layoutName: string;
};

const LayoutContext = createContext<LayoutType | null>(null);

function LayoutProvider({
  tenantId,
  layoutName,
  children,
}: React.PropsWithChildren<{ tenantId: string; layoutName: string }>) {
  const [layout, setLayout] = useState<LayoutType | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    appInsights?.addTelemetryInitializer((e) => {
      e.data = { ...e.data, tenant: tenantId, layoutName: layoutName };
    });
  }, [appInsights, tenantId, layoutName]);

  useEffect(() => {
    const cancelTokenSource = Axios.CancelToken.source();
    (async () => {
      setIsLoading(true);
      const response = await Axios.get<Omit<LayoutType, "name">>(`/api/tenants/${tenantId}/${layoutName}`, {
        cancelToken: cancelTokenSource.token,
      });
      const layout = { name: layoutName, ...response.data };
      setLayout(layout);
      setIsLoading(false);
    })().catch((e) => {
      /**
       * We have AxiosLoader component to catch Axios error but AxiosLoader is not set yet.
       * We need a way to catch the exception and throw it again so that react will catch it through ErrorBoundary
       * We use the setState method as a dirty hack for that
       *
       * see https://github.com/facebook/react/issues/14981#issuecomment-468460187
       *  */
      setLayout(() => {
        throw e;
      });
    });
    return () => {
      cancelTokenSource.cancel();
    };
  }, [tenantId, layoutName]);

  return (
    <>
      {/* calling useStyle/makeStyle will initialize a theme which will then be overriden by tenant configuration. 
          creating a theme it's quite a huge process. We ignore the theme because we only set these 2 inline styles properties
        */}
      {isLoading && <LinearProgress style={{ position: "fixed", width: "100%" }} />}
      <LayoutContext.Provider value={layout}>{layout && children}</LayoutContext.Provider>
    </>
  );
}

export { LayoutProvider, LayoutContext };
export type {
  LayoutType,
  CustomerRegistrationLayoutType,
  AddToWalletCompleteOptions,
  RedirectCompleteOptions,
  ResetCompleteOptions,
  RedirectToPassLayoutCompleteOptions,
  PassLayoutType,
  PassListLayoutType,
  ErrorLayoutType,
  LinkDisplayerLayoutType,
  Form,
  Field,
  Step,
  LayoutBehavior,
  FieldBehavior,
  PassCreationLayoutType,
  VendorLayoutType,
  PreRegistrationMethodConfiguration,
  FormRegistrationMethodConfiguration,
  SelfRegistrationMethodConfiguration,
};

export default function useLayout<T extends LayoutType>() {
  const layout = useOptionalLayout<T>();
  if (layout == null) {
    throw new Error("LayoutProvider not set");
  }
  return layout;
}

export function useOptionalLayout<T extends LayoutType>(): T | null {
  const layout = useContext(LayoutContext);
  if (layout == null) {
    return null;
  }

  return layout as T;
}
